<template>
        <content-loader v-if="loaded==false && slider!=true" class="load-global"
            viewBox="0 0 520 200"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="20" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="20" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="180" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="180" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="350" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="350" y="160" rx="3" ry="3" width="150" height="6" />
        </content-loader>
<!-- 
        <div v-if="landing==true" class="carousel-cell product" :class="{opaque:loaded==false && slider!=true}" >
        
            <img src="../../../../public/assets/collectionglass/space.gif" class="product-image-space" />

            <router-link :to="'/collection-beta'"></router-link>
            
        
            <div class="comingsoon"><span class="text">COMING SOON</span></div>
            

            <div v-if="loaded==false && slider==true">
                <content-loader class="loader-image"  
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader></div>

                <img class="comingsoonimg" src="../../../../public/assets/images/flou2.jpg"  />



        

            <div class="bottom">
                <div class="left">
                    <span class="product-title">CHAÏ
                       
                        <span class="subtitle"><br />Visages</span>
                    
                
                </span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> 160€</span>
                </div>
                
                
                
            </div>
        
    </div> -->


        <template v-for="(glass, index) in glasses" :key="glass.id">

           

        <div  v-if="(selectedFilter=='solar' && glass.solar==true) || selectedFilter!='solar' && glass.optic==true" class="carousel-cell product" :class="{opaque:loaded==false && slider!=true}" >
        
            <img src="../../../../public/assets/collectionglass/space.gif" class="product-image-space" />
            <router-link :to="'/collection-beta'" v-if="beta==true && betaAccess!=true"></router-link>
            <router-link :to="'/collection/'+glass.id+'/type/solar/?community=1'" v-else-if="community==true"></router-link>
            <template v-else-if="(selectedFilter=='solar' || selectedFilter=='bluelight') && selectedColorFilterHover!='all'">
                <a v-if="selectedFilter=='solar'" @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'solar', color:selectedColorFilterHover } })"></a>
                <a v-else-if="selectedFilter=='bluelight'" @click.self="$router.push({ name: 'collection-glass-color-type', params:{id:glass.id, type:'bluelight', color:selectedColorFilterHover} })" ></a>
            </template>

            <template v-else-if="(selectedFilter=='solar' || selectedFilter=='bluelight') && selectedColorFilter!='all'">
                <a v-if="selectedFilter=='solar'"  @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'solar', color:selectedColorFilter } })"></a>
                <a v-else-if="selectedFilter=='bluelight'" @click.self="$router.push({ name: 'collection-glass-color-type', params:{id:glass.id, type:'bluelight', color:selectedColorFilter} })" ></a>
            </template>

            <a v-else-if="selectedFilter == 'solar'" @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'solar' } })" ></a>
            <a v-else-if="selectedFilter == 'bluelight'" @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'bluelight' } })" ></a>
            <a v-else-if="selectedColorFilterHover!='all'" @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'optic', color:selectedColorFilterHover } })" ></a>
            <a v-else-if="selectedColorFilter!='all'" @click.self="$router.push({ name: 'collection-glass-color-type', params: { id: glass.id, type:'optic', color:selectedColorFilter } })"  ></a>
            <a v-else  @click.self="$router.push({ name: 'collection-glass', params: { id: glass.id } })"></a>
            
            
            
            <div v-if="hideHover==false && loaded==true" class="product-image-hover">
            
                <img v-for="(layer, index_layer) in photoHoverDatas[index]" @loadstart="loadStartHover(index)" @load="photosHoverLoaded[index]+=1"  :key="index_layer" :src="calculatePhoto(layer)" :class="{'render-layer2':index_layer>0}"  :style="'opacity:'+getOpacityElementHover(index,index_layer)+';'" />

                <content-loader class="loader-image" v-if="(photosHoverLoaded[index]<photoHoverDatas[index].length && glass.id!=22) || (photosHoverLoaded[index]<photoHoverDatas[index].length-1 && glass.id==22)"
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader>
            </div>
            
            

            <div v-if="loaded==false && slider==true">
                <content-loader class="loader-image"  
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader></div>

            <div v-if="loaded==true" class="photo-single product-image collec">
                <content-loader class="loader-image"  v-if="(photosLoaded[index]<photoDatas[index].length && glass.id!=22) || (photosLoaded[index]<photoDatas[index].length-1 && glass.id==22)"
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader>


                <img v-for="(layer, index_layer) in photoDatas[index]" :key="index_layer"  @load="photosLoaded[index]+=1" :src="calculatePhoto(layer)" :class="{'render-layer2':index_layer>0}"  :style="'opacity:'+getOpacityElementHover(index,index_layer)+';'" />

            
            </div>

            <div class="personnalisation-actions" :class="{beta:beta==true}">
                <template v-if="beta!=true">
                    
                
                    <div v-if="language=='fr'" class="personnalisation-text"><img src="../../../../public/assets/images/edit1.svg" width="13" /> PERSONNALISABLE</div>
                    <div v-else class="personnalisation-text"><img src="../../../../public/assets/images/edit1.svg" width="13" />  CUSTOMIZABLE</div>
                </template>
                <template v-else>
                    |&nbsp;&nbsp;&nbsp; <img src="../../../../public/assets/images/beta.png" width="55" height="auto" /> 
                </template>
            </div>

            <div  v-if="language=='fr'" class="colors-count">{{ glass.colors.length }} COULEURS</div>
            <div  v-else class="colors-count">{{ glass.colors.length }} COLORS</div>

            <template v-if="beta!=true">
                <div class="like" @click="likePhoto(glass.liked, glass.like_id, index)">
                    <img v-if="glass.liked == false" src="../../../../public/assets/images/heart.svg" width="23" />
                    <img v-else src="../../../../public/assets/images/heart.png" width="23" />
                </div>
            </template>
            <template v-else>
               
                    <img src="../../../../public/assets/images/edit1.svg" class="like" width="18" /> 
                

            </template>

            <div class="bottom">
                <div class="left">
                    <span class="product-title">{{ glass.name }}
                        <span class="subtitle" v-if="community==true"><br /><img src="../../../../public/assets/images/pic.png" width="18" /> <span v-if="language=='fr'">Personnalisé par</span><span v-else>Customized by</span> <u>Milo</u></span>
                        <span class="subtitle" v-else><br />{{glass.brand_name}}</span>
                      
                
                </span>
                    <span class="product-price"><span class="from" v-if="language=='fr'">À partir de</span><span class="from" v-else>From</span> <span v-if="glass.optic!=true">{{ glass.price+20 }}</span><span v-else>{{ glass.price }}</span>€</span>
                </div>
                
                    <template v-if="current_colors.length>0">
                    <div class="colors" @mouseleave="resetColor(index)" v-if="current_colors[index] && community!=true">
                        <template v-for="(color, indexcolor) in current_colors[index]" :key="color.id">
                            <div class="color" :class="{active:(indexcolor==0 && selectedColorFilterHover=='all') || selectedColorFilterHover==color.id}" v-if="indexcolor<13">
                                <a @mouseover="hoverColor(color.id, index)" class="contenudesk" v-if="selectedFilter == 'solar'" > 
                                    <div class="color-bg" :style="'background-image:url('+color.photo+');'">
                                    </div>
                                </a>
                                <a  @mouseover="hoverColor(color.id, index)"  v-else-if="selectedFilter == 'bluelight'" > 
                                    <div class="color-bg" :style="'background-image:url('+color.photo+');'">
                                    </div>
                                </a>
                                <a @mouseover="hoverColor(color.id, index)"   v-else  > 
                                    <div class="color-bg" :style="'background-image:url('+color.photo+');'">
                                    </div>
                                </a>

                                
                            </div>
                        </template>
                        <div class="plus">
                            <img v-if="current_colors[index].length>13" src="../../../../public/assets/home/plus.png" />
                        </div>
                    </div>
                </template>
                
            </div>
           
    </div>
</template>
    <!-- <AvailableSoon v-for="(l,index) in loop" :key="index"/> -->

</template>

<script>
import AvailableSoon from '../../cards/AvailableSoonCardComponent.vue';
import {APIRequest} from "@simb2s/senseye-sdk"
import { ContentLoader } from 'vue-content-loader'
export default {
    name: "CollectionProduct",
    components: {
        AvailableSoon,
        ContentLoader
    },
    props: ["glasses", "selectedFilter","selectedColorFilter","community","slider","language","beta","betaAccess", "landing"],
    watch: {
    // whenever question changes, this function will run
    selectedFilter: async function (newQuestion, oldQuestion) {
        this.loaded=false
        this.loadingFilter=true
        this.current_colors=[]
      await this.calculatePhotos()
      
      this.loaded=true
      

      
    }
  },
    methods:{
        loadStartHover(index){
            if(this.loaded==true)
            {
                this.photosHoverLoaded[index]-=1
            }
           
        },
        getBrightnessElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.brightness != null) {
                return this.photoHoverDatas[index][layer].presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.hue != null) {
                return this.photoHoverDatas[index][layer].presets.hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.saturation != null) {
                return this.photoHoverDatas[index][layer].presets.saturation/100
            }
            else {
                return 1
            }
        },
        getOpacityElementHover(index, layer) {
            if(this.photoHoverDatas[index][layer].presets.opacity != null) {
                return this.photoHoverDatas[index][layer].presets.opacity/100
            }
            else {
                return 1
            }
        },

        getBrightnessElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].brightness != null) {
                return this.photoDatas[index].presets['layer'+layer].brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].hue != null) {
                return this.photoDatas[index].presets['layer'+layer].hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].saturation != null) {
                return this.photoDatas[index].presets['layer'+layer].saturation/100
            }
            else {
                return 1
            }
        },
        getOpacityElement(index, layer) {
            if(this.photoDatas[index].presets['layer'+layer].opacity != null) {
                return this.photoDatas[index].presets['layer'+layer].opacity/100
            }
            else {
                return 1
            }
        },
        collectionPhotoLoaded(index) {
       
            this.collectionPhotosLoading[index]=false
        },
        updateSlider()
        {
            this.$emit('updateSliderEv')
        },
        likePhoto(glass,id,index)
        {
            this.$emit('likePhotoEv', glass, id, index)
        },
        calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        async calculatePhotos(){
            this.photosLoaded=[]
            this.photosHoverLoaded=[]
            this.photoDatas=[]
            this.photoHoverDatas=[]
            this.current_colors=[]
            
            let model_parts_arr=[]
            for(let i=0;i<this.glasses.length;i++){
                this.photosLoaded.push(0)
                this.photosHoverLoaded.push(0)
                let current_colors=[]
                let request_modelparts = '?glass='+this.glasses[i].id
                let model_parts =  await APIRequest.list_with_request('model-parts',request_modelparts)

                let model_parts_data=model_parts[0]

                
                model_parts_arr.push(model_parts_data)
                

               
                

                for(let p=0;p<model_parts_data.length;p++)
                {
                    if((model_parts_data[p].type=='color' || model_parts_data[p].type=='texture_color') &&  model_parts_data[p].render_slug=='frame' )
                    {
                        if(this.current_colors.indexOf(i) == -1)
                        {
                            this.current_colors.push(model_parts_data[p].colors)
                        }
                        
                    }
                }

                // console.log('ok')
                // console.log(current_colors)
                // this.current_colors.push(current_colors)
                
            
                let request = '?glass='+this.glasses[i].id+'&branch=4'
                for(let j=0;j<model_parts_data.length;j++) {
                
                    
                
                if(model_parts_data[j].render_type!="shadow" && model_parts_data[j].render_type!="hinge" && model_parts_data[j].render_type!="manchon"){
                    if(model_parts_data[j].type=='color') {
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    if(this.glasses[i].id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=10'
                                    }
                                }
                               

                                
                                
                            }
                            else 
                            {
                                if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                    if(this.glasses[i].id==29)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'=6'
                                    }
                                    else
                                    {
                                        if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                                        }
                                    }
                                }
                               
                                
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                            }
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                           
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                            
                        }
                        
                        }
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                    
                    }
                    else if(model_parts_data[j].type=='texture_color') { 
                
                
                        if(model_parts_data[j].render_slug=="frame" || model_parts_data[j].render_slug=="frame1")
                        {

                            if(this.glasses[i].default_color!=null && model_parts_data[j].render_slug!="frame1")
                            {
                                request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                            }
                            else
                            {

                                if(model_parts_data[j].material=="plastic")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'=19'
                                }
                            }
                            
                            request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else if(model_parts_data[j].render_slug=="temples")
                        {
                
                        
                            if(model_parts_data[j].material=="plastic")
                            {
                                if(this.glasses[i].default_color!=null)
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[i].default_color.id
                                }
                                else
                                {
                                request+='&color_'+model_parts_data[j].render_slug+'=10'
                                }
                            }
                            else {
                                if(model_parts_data[j].colors.length)
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'='+model_parts_data[j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+model_parts_data[j].render_slug+'=20'
                                        }
                               
                            }
                        
                
                        request+='&texture_style_'+model_parts_data[j].render_slug+'=0'
                        }
                        else {
                        if(model_parts_data[j].material=="plastic")
                        {
                            request+='&color_'+model_parts_data[j].render_slug+'=10'
                        }
                        else {
                            request+='&color_'+model_parts_data[j].render_slug+'=19'
                        }
                        
                        }
                        
                        request+='&texture_'+model_parts_data[j].render_slug+'=4'
                
                    }
                    else if (model_parts_data[j].render_type=="lense")
                    {
                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else 
                        {
                            if(this.glasses[i].default_tint!=null)
                            {
                                default_tint=this.glasses[i].default_tint.id
                            }
                               
                        }

                        
                        
                      
                        request+='&tint_'+model_parts_data[j].render_slug+'='+default_tint
                        
                    }
                    
                    
                    
                    }
                    else if (model_parts_data[j].render_type=="manchon")
                    {
                        request+='&color_'+model_parts_data[j].render_slug+'=24'
                   
                    }
                }
                
                
                let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                let render_infos_datas=render_infos
                
                if(this.glasses[i].id==26)
                {
                    this.photoHoverDatas.push(render_infos_datas.scene2)
                    this.photoDatas.push(render_infos_datas.scene1) 
                }
                else {
                    this.photoHoverDatas.push(render_infos_datas.scene4)
                    this.photoDatas.push(render_infos_datas.scene2) 
                }

                
            
                 
                
               
            }
            
            this.model_parts=model_parts_arr

            this.loaded=true

            
           
        },
        async calculatePhotoHover(index){
           
            let request = '?glass='+this.glasses[index].id+'&branch=4'

            

            for(let j=0;j<this.model_parts[index].length;j++) {
                
                    
                
                if(this.model_parts[index][j].render_type!="shadow" && this.model_parts[index][j].render_type!="hinge" && this.model_parts[index][j].render_type!="manchon"){
                    if(this.model_parts[index][j].type=='color') {
                        if(this.model_parts[index][j].render_slug=="frame" || this.model_parts[index][j].render_slug=="frame1")
                        {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].render_slug=="frame") {
                                
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    

                                    if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        if(this.glasses[index].id==29)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=6'
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                        }
                                    }
                                }
                                else 
                                {
                                    if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                    }
                                }
                            }
                            //ici color
                            
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else if(this.model_parts[index][j].render_slug=="temples")
                        {

                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    if(this.glasses[index].default_color!=null)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        if(this.glasses[index].id==29)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=6'
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                        }
                                    }
                                    
                                }
                                else 
                                {
                                    
                                    if(this.model_parts[index][j].colors.length)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'='+this.model_parts[index][j].colors[0].id
                                        }
                                        else
                                        {
 request+='&color_'+this.model_parts[index][j].render_slug+'=20'
                                        }

                                   
                                }
                            }
                            //ici color
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                }
                            }
                        
                        }
                        request+='&texture_'+this.model_parts[index][j].render_slug+'=4'
                    
                    }
                    else if(this.model_parts[index][j].type=='texture_color') { 
                
                
                        if(this.model_parts[index][j].render_slug=="frame" || this.model_parts[index][j].render_slug=="frame1")
                        {
                            if(this.selectedColorFilterHover!='all') {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {

                                if(this.glasses[index].default_color!=null && model_parts_data[j].render_slug!="frame1")
                                {
                                    request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                }
                                else
                                {
                                    
                                    if(this.model_parts[index][j].material=="plastic")
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                    }
                                    else 
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                    }
                                }
                            }
                            
                            request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else if(this.model_parts[index][j].render_slug=="temples")
                        {
                
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    if(this.glasses[index].default_color!=null)
                                    {
                                        request+='&color_'+model_parts_data[j].render_slug+'='+this.glasses[index].default_color.id
                                    }
                                    else
                                    {
                                        request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                    }
                                }
                                else 
                                {
                                    if(this.model_parts[index][j].colors.length)
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'='+this.model_parts[index][j].colors[0].id
                                        }
                                        else
                                        {
                                            request+='&color_'+this.model_parts[index][j].render_slug+'=20'
                                        }
                                }
                            }
                
                        request+='&texture_style_'+this.model_parts[index][j].render_slug+'=0'
                        }
                        else {
                            if(this.selectedColorFilterHover!='all' && this.model_parts[index][j].material=="plastic") {
                                request+='&color_'+this.model_parts[index][j].render_slug+'='+this.selectedColorFilterHover
                            }
                            else {
                                if(this.model_parts[index][j].material=="plastic")
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=10'
                                }
                                else 
                                {
                                    request+='&color_'+this.model_parts[index][j].render_slug+'=19'
                                }
                            }
                        
                        }
                        
                        request+='&texture'+this.model_parts[index][j].render_slug+'=4'
                
                    }
                    else if (this.model_parts[index][j].render_type=="lense")
                    {
                        // ici tint

                        // ici tint
                        let default_tint=4
                        if(this.selectedFilter=='optic')
                        {
                            default_tint=5
                        }
                        else
                        {
                            if(this.glasses[index].default_tint!=null)
                            {
                                default_tint=this.glasses[index].default_tint.id
                            }
                        }
                        request+='&tint_'+this.model_parts[index][j].render_slug+'='+default_tint
                        // request+='&tint_'+this.model_parts[index][j].render_slug+'=4'
                        
                    }
                    
                    
                    
                    }
                    else if (this.model_parts[index][j].render_type=="manchon")
                    {
                        request+='&color_'+this.model_parts[index][j].render_slug+'=24'
                   
                    }
                }
                this.loaded=true
                   

                let datas =  await APIRequest.list_renders('photos-layers-new',request)
                if(this.glasses[index].id==26)
                {
                    this.photoHoverDatas[index]=datas.scene2
                }
                else
                {
                    this.photoHoverDatas[index]=datas.scene4
                }
               

        },
        hoverColor(color, index) {
            this.selectedColorFilterHover=color
            this.collectionPhotosLoading[index]=true
           

            this.calculatePhotoHover(index)
            this.hideHover=false

        },
        resetColor(index) {
            this.hideHover=false
            this.selectedColorFilterHover='all'

            this.calculatePhotoHover(index)
            this.collectionPhotosLoading[index]=true
        },
    },
    data(){
        return{
            hideHover:false,
            collectionPhotosLoading:[],
            loop: 3,
            model_parts:null,
            loadingFilter:false,
            current_colors:[],
            loaded:false,
            photosLoaded:[],
            photosHoverLoaded:[],
            photoHoverDatas:null,
            photoDatas:null,
            selectedColorFilterHover:'all'
        }
    },
    async mounted(){
        await this.calculatePhotos()
        this.loaded=true
    },
    updated(){
       
        
    }
}
</script>

<style scoped>
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:3;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
    z-index:3;
}

.colors-count
{
    
    font-size:13px;

}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product {
  margin-bottom:150px;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF00;
  border-radius: 8px;
  padding:5px 10px 5px 25px;
}
.like img {
    width:23px !important;
}
.comingsoon .text 
{
    position:absolute;
    left:50%;
    top:50%;
    font-size:20px;
    transform: translate(-50%,-50%);
}
.comingsoonimg
{
    max-height:200% !important;
    height:100% !important;
    position: absolute;
    top:0;
}
.comingsoon
{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    z-index:4;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(20deg);
}
.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:13;
    text-align: right;
    right:15px;
}.personnalisation-text
{
    position:relative;
    top:2px;
    margin-left:5px;
    color:transparent;
    font-size:12px;
    background: transparent linear-gradient(89deg, rgba(0, 0, 255, 0.6) 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
opacity: 1;
background-clip:text;
}
.like {
    right:20px;
    top:20px; 
}
.personnalisation-actions.beta img {
    display:inline-block;
    width:55px !important;
    height:14px !important;
    position: relative;
    top:4px;
}
.personnalisation-actions {
    display:flex;
    left:20px;
    top:20px;
}
.product-image-space.loading {
    position:relative;
    z-index:2;
    backdrop-filter: blur(30px);
}
.product-image {
width:100%;
height:auto;
}
.product-image.collec {
    position:absolute;
    left:0;
    
    top:50%;
    transform: translateY(-50%);
    
}
.product-image-space {
    width:100%;
    height:auto;
    margin-top:30px;
    margin-bottom:30px;
}
.render-layer2 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 2;
}
.product.opaque {
    opacity:0 !important;
}
.product-price .from {
    font-size:13px;
color:#ACACAC;
font-weight: 400;
}
.product-price {
    font-size:21px !important;
    font-weight: 600;
}
.render-layer3 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 3;
}
div.products div.product .bottom .product-price {
    float:right;
}
.personnalisation-text img {
    display: inline-block;
}
.render-layer4 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 4;
}
.render-layer5 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 5;
}
.render-layer6 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 6;
}
.render-layer7 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 7;
}

.photo-single {
    position: relative;
    z-index: 2;
}
.product-image-hover {
    position:absolute;
    width:100%;
    height:auto;
    z-index:8;
    left:0;
    background: #F8F8F8;
    top:0;
    transition:all 0.5s;
    opacity:0;
}

.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:13;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.products .product .bottom .product-title .subtitle img {
    display: inline-block;
    position:relative;
    top:2px;
}
.products .product .bottom .product-title .subtitle {
    font-size:18px;
    font-weight: 400;
    text-transform: none;
}
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:22px;
    font-weight: 600;
	text-transform: uppercase;
}

.products .product .bottom .left {
    width:100%;
    position:relative;
    left:-10px;
    line-height: 1;
    top:70px;
}
.products .product .bottom .right {
    text-align: right;
    width:60%;
}
.colors-count {
    position:absolute;
    right:20px;
    bottom:20px;
    z-index:2;
}
.products .product:hover .bottom .colors {
    opacity:1;
}
.products .product {
    margin-bottom:150px;
}
.products .product .bottom .colors {
    opacity:0;
    transition:all 0.3s;
    position:absolute;
    bottom:0;
    left:0;
    width:95%;
    padding:10px;
    display:flex;
    justify-content: center;
    background:#fff;
    align-items: center;
}
.load-global {
    width:80vw !important;
    max-width:80vw !important;
    position:absolute;
    left:50%;
    transform: translateX(-50%);
}
.loader-image {
    position:absolute;
    top:0;
    left:0;
    z-index:10;
}
.products .product .bottom .colors .color {
    display:flex;
    width:16px;
    cursor: pointer;
    height:16px;
    border:1px solid #C0C0C0;
    position:relative;
    border-radius:50%;
    margin-right:5px;
    margin-left:5px;
}
.products .product .bottom .colors .color.active {
    border:1px solid #000;
}
.products .product .bottom
{
    display:flex;

}
.products .product .bottom .colors .plus {
    margin-right: 20px;
}
.products .product .bottom .colors .plus img {
    width:11px;
}
.products .product .bottom .colors .color .color-bg {
    width:14px;
    height:14px;
    border-radius:50%;
    position:absolute;
    left:0px;
    top:0px;
    border:0;
    background-size:cover;
    background-position:center center;
}
div.products div.product .bottom {
    z-index:14;
    bottom: 10px;
    left:10px;
}
</style>