<template>
    <div id="cart-items" v-bind:class="{downloadrow: onlycollab==false && displayDownloadRow && userScans.length==0}">
        <span v-if="cart_items">
            <div v-if="cart_items.length>0" class="cart-items-items">
                <div v-for="(cart_item, index) in cart_items" :key="index">
                    <div v-if="cart_item.glass.type=='accessoire'" class='cart-item'>
                        <img class='product-img' :src='photos[index]' @click="displayPhotoBig(photos[index])" />
                        <div class='product-details'>
                            <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span>
                            <a class='remove' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                <img :data-item='cart_item.id' width='20'
                                    src='../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png' />
                            </a>
                            <div class='name'>{{cart_item.glass.name}}

                                <p>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div>
                    <!-- <div v-else-if="cart_item.glass.type=='collaboration'" class='cart-item'>
                        <img class='product-img' @click="displayPhotoBig(cart_item.product_variation_item.photos[0])" :src='cart_item.product_variation_item.photos[0]' />
                        <div class='product-details'>
                            <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span>
                            <a class='remove' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                <img :data-item='cart_item.id' width='20'
                                    src='../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png' />
                            </a>
                            <div class='name'>{{cart_item.glass.name}}
                                <p v-if="cart_item.product_variation_item!=null">
                                    {{cart_item.product_variation_item.name}}</p>
                                <p v-else>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div> -->
                    <div v-else class='cart-item'>

                       <div class="left-cart-item">
                            <div class='name'>
                                {{cart_item.glass.name}}
                                
                            </div>
                            <CartImage :cart_item="cart_item" />
                            <div class="actions-item">
                                <span class='quantity'>
                                    <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                    <span class='qtty'>{{cart_item.quantity}}</span>
                                    <span class='plus' @click='plusCartItem($event,cart_item.id)'>+</span>
                                </span>
                                <div class='item-amount'>{{cart_item.amount}}€</div>
                                <a class='remove' @click='removeItem(cart_item.id)' :data-item='cart_item.id'>
                                    <img :data-item='cart_item.id' width='20'
                                        src='../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png' />
                                </a>
                            </div>
                        </div>

                        <div class='product-details'>
                           
                           
                            
                            <div class='product-detail' @click="toggleCart('glass',index)" :class="{open:toggleGlass[index]}">
                                <label>
                                    <span v-if="cart_item.glass_option!==null && cart_item.glass_type!==null"><span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span> - {{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else-if="cart_item.glass_option!==null">
                                        <span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span>
                                        
                                        - <span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                    <span v-else-if="cart_item.glass_type!==null">{{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else><span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                </label>
                                <img width='6' class="right-icon plus" src='../../../../public/assets/images/plus2.svg' />
                                <img width='6' class="right-icon minus"
                                    src='../../../../public/assets/images/minus2.svg' />
                                <div class="product_detail_content" v-if="toggleGlass[index]">
                                    <template v-if="language=='fr'">
                                        Verres :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name}}</span>
                                        <span v-else>Aucune option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Type de verre :
                                            {{cart_item.glass_type.name}} </span>


                                        <span v-if="cart_item.tint!==null"><br />Teinte de verre : {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                    <template v-else>
                                        Lenses :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                                        <span v-else>No option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Lens type:
                                            {{cart_item.glass_type.name.replace('Sans correction','No correction')}} </span>


                                        <span v-if="cart_item.tint!==null"><br />Lens tint: {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <div v-if="language=='fr'"  class='product-detail' @click="toggleCart('frame',index)" :class="{open:toggleFrame[index]}">
                                <label>Monture {{cart_item.color.name}}</label>
                                <img width='6' class="right-icon plus" src='../../../../public/assets/images/plus2.svg' />
                                <img width='6' class="right-icon minus" src='../../../../public/assets/images/minus2.svg' />
                                <div class="product_detail_content" v-if="toggleFrame[index]">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-else>
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture">
                                             
                                                Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture_style">
                                               <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>


                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Gravure :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                </div>
                            </div>

                            <div v-else class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                                <label>Frame {{cart_item.color.name}}</label>
                                <img width='7' class="right-icon plus" src='../../../../public/assets/images/plus2.svg' />
                                <img width='7' class="right-icon minus" src='../../../../public/assets/images/minus2.svg' />
                                <div class="product_detail_content" v-if="toggleFrame[index]">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span v-if="order_item_customization.model_part.type=='color'">
                                                Color {{order_item_customization.model_part.name}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span v-else>
                                                Color {{order_item_customization.model_part.name}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span v-if="order_item_customization.texture">
                                                <br />Texture {{order_item_customization.model_part.name}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span v-if="order_item_customization.texture_style">
                                                <br />Texture style {{order_item_customization.model_part.name}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Engraving :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                    <div v-if="cart_item.size!=null || cart_item.scan!=null">
                                        <template v-if="cart_item.scan!=null">Scan : {{cart_item.scan.name}}</template>
                                        <template v-else-if="cart_item.size!='onmeasure'">Size : {{cart_item.size}}</template>
                                        
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="personnalisation">
                                <div class="head">
                                    <div class="toggles">
                                        <div class="toggle" v-if="language=='fr'" @click="() => toggleSelect('custom', index)"
                                            v-bind:class="{active : activeToggle[index]=='custom'}">
                                            <img src="../../../../public/assets/images/locked.svg" width="10" v-if="cart_item.glass.custom_fit!=true" />
                                            Sur-mesure</div>
                                            <div class="toggle" v-else @click="() => toggleSelect('custom', index)"
                                            v-bind:class="{active : activeToggle[index]=='custom'}"> <img src="../../../../public/assets/images/locked.svg" width="10" v-if="cart_item.glass.custom_fit!=true" /> Custom-fit</div>
                                        <div class="toggle" @click="() => toggleSelect('size', index)"
                                            v-bind:class="{active : activeToggle[index]=='size'}">One size</div>
                                        <div class="toggle-bg" :style="'left:'+togglePosition[index]+'%'"></div>
                                    </div>

                                   <img src="../../../../public/assets/images/question.svg" class="question-size"  v-if="activeToggle[index]!='size'" @click="displayHowItWork(index)" width="20" />

                                   <div class="popup-size" v-if='displayOnMesurePopup[index]'>

<div class="content">
    <span id="cross"
        @click="displayMesuresFn(index)">
        <img
            src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png"
            width="12" /></span>


    <div class="size-description">
        Sélectionner un scan de votre visage effectué au préalable sur
        l’application iOS Visages.<br />
        Le scan sera utilisé pour ajuster la lunette.<br /><br />
        Vous souhaitez effectuer un nouveau Scan? <br />
        <a>Sélectionnez "+ Nouveau scan"</a>
    </div>

</div>
</div>

<div class="popup-size popup-size-new" @click="(e) => closeModal(e)" v-if='displayHowItWorkPopup[index]'>
    <div class="content">
                                            <span id="cross" @click="displayHowItWorkPopupToFalse"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
                                            <div class="content-size-popup">
                                                <div class="left-size-popup">
                                                    <img src="../../../../public/assets/images/sizepopup.jpg" />
                                            
                                                </div>
                                                <div class="right-size-popup">
                                                    <div class="title-popup-size"><span v-if="langage=='fr'" >Explication de taille du modèle</span><span v-else >Size explanation</span></div>
                                                    <br />  <br />
                                                    <img src="../../../../public/assets/images/size2.jpg" class="size-popup-img" />
                                                    <br />
                                                    <div class="size-elements">
                                                        <div class="size-element">
                                                            
                                                            <div v-if="langage=='fr'" class="size-element-left">Taille</div>
                                                            <div v-else class="size-element-left">Size</div>
                                                            <div class="size-element-right">Standard</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Faces</div>
                                                            <div v-else class="size-element-left">Front</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                        <div class="size-element">
                                                            <div v-if="langage=='fr'" class="size-element-left">Branches</div>
                                                            <div v-else class="size-element-left">Temples</div>
                                                            <div class="size-element-right">30x15cm</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
<!-- <div class="popup-size" v-if='displayHowItWorkPopup[index]'>
<div class="popup-size" v-if='displayHowItWorkPopup'>
    <div class="content">
        <span id="cross" @click="displayHowItWorkFn(index)"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
        <div class="size-name">Taille 
            <span v-for="size in sizes" :key="size.id">
                <span v-if="calculateSizePopup(index)==size.value">{{size.label}}</span>
            </span>
        </div>
        
        
        <div class="size_choice">
            <span v-for="size in sizes" :key="size.id">
                <div class="size" @click="selectSize(index,size.value)" v-bind:class="{active : calculateSizePopup(index)==size.value}"><span>{{size.label}}</span></div>
            </span>
        </div>
        <div class="size-description">

            <span v-html="sizeSentenceFactory(calculateSizePopup(index))"></span>

        </div>
        <img src="../../../../public/assets/images/size.jpg" class="size-image" />
        
    </div>
</div>

</div> -->
                                    <!-- <div class="size_choice" v-if="activeToggle[index]=='size'">
                                        <div class="info">
                                            <a class="link" @click="displayHowItWork(index)"> <img
                                                    src="../../../../public/assets/images/question.svg" width="20" /></a>
                                        </div>

                                        <div class="popup-size" v-if='displayOnMesurePopup[index]'>

                                            <div class="content">
                                                <span id="cross"
                                                    @click="displayMesuresFn(index)">
                                                    <img
                                                        src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png"
                                                        width="20" /></span>


                                                <div class="size-description">
                                                    Sélectionner un scan de votre visage effectué au préalable sur
                                                    l’application IOS Senseye.<br />
                                                    Le scan sera utilisé pour ajuster la lunette.<br />
                                                    Vous souhaitez effectuer un nouveau Scan? <br />
                                                    <a href="#">Cliquez-ici</a>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="popup-size" v-if='displayHowItWorkPopup[index]'>
                                            <div class="popup-size" v-if='displayHowItWorkPopup'>
                                                <div class="content">
                                                    <span id="cross" @click="displayHowItWorkFn(index)"><img src="../../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="20" /></span>
                                                    <div class="size-name">Taille 
                                                        <span v-for="size in sizes" :key="size.id">
                                                            <span v-if="calculateSizePopup(index)==size.value">{{size.label}}</span>
                                                        </span>
                                                    </div>
                                                    
                                                    
                                                    <div class="size_choice">
                                                        <span v-for="size in sizes" :key="size.id">
                                                            <div class="size" @click="selectSize(index,size.value)" v-bind:class="{active : calculateSizePopup(index)==size.value}"><span>{{size.label}}</span></div>
                                                        </span>
                                                    </div>
                                                    <div class="size-description">

                                                        <span v-html="sizeSentenceFactory(calculateSizePopup(index))"></span>

                                                    </div>
                                                    <img src="../../../../public/assets/images/size.jpg" class="size-image" />
                                                    
                                                </div>
                                            </div>
                                    
                                        </div>
                                        <div class="title-size" v-if="sizes">
                                            <span v-if="userScans.length>0 && cart_item.scan!=null">Taille recommandée
                                                pour le scan {{selectedScan[index]}} :
                                                "{{recommandedSize[index]}}"</span>
                                            <span v-else>Taille
                                                <span v-for="size in sizes" :key="size.id">
                                                    <span v-if="cart_item.size==size.value">{{size.label}}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <span v-for="size in sizes" :key="size.id">
                                            <div class="size" @click="selectSize(index,size.value)"
                                                v-bind:class="{active : cart_item.size==size.value}"><span>{{size.label}}</span>
                                            </div>
                                        </span>
                                    </div> -->



                                </div>
                                
                                    
                                        
                                            <span v-if="user!=null">
                                                <span v-if="userScans!=null && userScans.length>0">
                                                    <div class='product-detail scans' v-if="activeToggle[index]=='custom'">
                                                    <span class='scans_content' id='scans_content'>
                                                    <label ><b v-if="language=='fr'">Scan sélectionné pour l'ajustage</b> <b v-else>Selected scan for fitting</b> 
                                        <!-- <img src="../../../../public/assets/images/question.svg"  @click="displayHowItWork(0)" width="11" /> -->
                                        <br />
                                                    <span v-if="userScans.length>0">
                                                        <div class="scan-choice"  :class="{opened:displayScans}" @click="toggleDisplayScans">
                                                            <span v-if="cart_item.scan!=null">{{cart_item.scan.name}}</span>
                                                            <span v-else-if="selectedScan[index]">{{selectedScan[index]}}</span>
                                                            <span v-else>
                                                                    <span v-if="language=='fr'">Sélectionner</span>
                                                                    <span v-else>Select</span>
                                                            </span>
                                                        </div>
                                                        <div class="options-scan" v-if="displayScans">
                                                            <div class="option" v-if="language=='fr'"
                                                                @click.prevent="selectScan(index,null,null)">
                                                                + Nouveau scan</div>
                                                                <div class="option" v-else
                                                                @click.prevent="selectScan(index,null,null)">
                                                                + New scan</div>
                                                            <div class="option" v-for="scan in userScans" :key="scan.id"
                                                                @click.prevent="selectScan(index,scan.id,scan.name)">
                                                                {{scan.name}}</div>
                                                        </div>
                                                    </span>
                                                    <!-- <span v-else>
                                                        <a @click="displayAppPopupToTrue">Connectez-vous sur
                                                            l'application</a> afin de scanner votre visage pour obtenir
                                                        une lunette parfaitement ajusté à votre visage
                                                    </span> -->
                                                </label>
                                                </span>
                                                <!-- <span v-else>
                                                    <a @click="displayAppPopupToTrue">Connectez-vous sur
                                                        l'application</a> afin de scanner votre visage pour obtenir une
                                                    lunette parfaitement ajusté à votre visage
                                                </span> -->
                                            </div>
                                            </span>
                                            <!-- <span v-else>
                                                <a @click="displayLogin()">Connectez-vous</a> afin de scanner votre
                                                visage pour obtenir une lunette parfaitement ajusté à votre visage
                                            </span> -->
                                       
                                        </span>
                                   
                                </div>
                           

                        </div>
                        <div class="like" @click="likePhoto(cart_item.glass.liked==true ? true : false, cart_item.glass.like_id,cart_item.glass.id,index)">
                            <img v-if="cart_item.glass.liked == true" src="../../../../public/assets/images/heart.png" width="26" />
                            <img v-else src="../../../../public/assets/images/heart.svg" width="26" />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-else>
                <span v-if="language=='fr'">Aucun produit dans le panier</span><span v-else>No product in the cart</span>
                
            </div>
        </span>
        <span v-else>
            <span v-if="language=='fr'">Aucun produit dans le panier</span><span v-else>No product in the cart</span>
        </span>
    </div>
    <div v-if="displayBigPhoto" @click="closeModal" class="popup-photo-big">
        <div class="content">
            <img src="../../../../public/assets/home/cross.svg" class="cross" @click="closePhotoBig" />


            <inner-image-zoom :src="bigPhoto" :zoomSrc="bigPhoto" class="photo"  />
        </div>
    </div>
</template>

<script>
import sizeSentenceFactory from '../../../data/collectionGlass/text_size.js'
import { useOrderItemStore } from '../../../store/orderItemStore'
import CartImage from "./CartImageComponent.vue";
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

export default {
    name: "CartItemComponent",
    props: ["language","cart_items","onlycollab","displayDownloadRow","user","userScans","cart","photos","toggleGlass","toggleFrame","activeToggle","togglePosition",
            "displayOnMesurePopup","displayPopupBg","displayHowItWorkPopup","sizes","selectedScan","recommandedSize","displayScans","displayAppPopup"],
            components: {
                CartImage,
    'inner-image-zoom': InnerImageZoom
  },
    methods: {

        likePhoto(glass,id,index,index2)
        {
            this.$emit('likePhotoEv', glass, id,index, index2)
        },

        closeModal($event)
        {
            
            if(jQuery($event.target).parents('.popup-photo-big .content').length)
            {
              
            }
            else
            {
                this.displayBigPhoto=false
                $event.preventDefault()
            }
            
        },
        displayPhotoBig(photo) {
            this.bigPhoto=photo
            this.displayBigPhoto=true
        },
        closePhotoBig() {
            this.displayBigPhoto=false
        },
        selectSizePopup(size) {
            this.activeSizePopup = size
        },
        minusCartItem(e,id,quantity){
            this.$emit('minusCartItemEv', e,id,quantity)
        },
        plusCartItem(e,id){
          
            this.$emit('plusCartItemEv', e,id)
        },
        removeItem(id){
            this.$emit('removeItemEv', id)
        },
        toggleCart(str,index){
            this.$emit('toggleCartEv', str,index)
        },
        toggleSelect(str, i){
            this.$emit('toggleSelectEv', str, i)
        },
        displayHowItWork(i){
            this.$emit('displayHowItWorkEv', i)
        },
        selectSize(i, value){
            this.$emit('selectSizeEv', i, value)
        },
        selectScan(i,id,name){
            this.$emit('selectScanEv',i,id,name)
        },
        displayLogin(){
            this.$emit('displayLoginEv')
        },
        displayMesuresFn(index){
            this.$emit('displayMesuresFnEv', index)
        },
        displayHowItWorkFn(index){
            this.$emit('displayHowItWorkFnEv', index)
        },
        toggleDisplayScans(){
            this.$emit('toggleDisplayScansEv')
        },
        displayAppPopupToTrue(){
            this.$emit('displayAppPopupToTrueEv')
        },
       
        
        calculateSizePopup(index) {
            if(this.cart_items[index].size!=null && this.cart_items[index].size!="") {
                return this.cart_items[index].size;
            }
            else {
                return "m";
            }
        }
                
    },
    data(){
      
        return{
            useOrderItem: useOrderItemStore(),
            sizeSentenceFactory,
            displayBigPhoto:false,
            bigPhoto:null,
            activeSizePopup: 'm',
        }
    },
    computed: {
    
    
    },
}
</script>

<style scoped>

@media screen and (max-width:760px) {
    .left-glass-option-content img
    {
        width:40% !important;
    }
    .popup-glass-option-content {
        flex-direction: column;
    }
    .left-glass-option-content
    {
        margin-bottom:20px;
    }
    .left-glass-option-content,.right-glass-option-content
    {
        width:100% !important;
    }
    .content-size-popup .left-size-popup,
    .content-size-popup .right-size-popup
    {
        width:100% !important;
    }
    .size-element,
    .content-size-popup{
        flex-direction:column;
    }
    .size-element-left,
    .size-element-right,
    
    .table-color .right-table,
    .table-color .left-table {
        display:block !important;
        width:100% !important;
        font-size:12px !important;
    }
    .size-element-right {
        padding-left:37px;
    }
    .size-element-right,
    .table-color .right-table
    {
        padding-top:10px;
    }
    .size-element-left,
    .table-color .left-table {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    }
    .col-title
    {
        width:45% !important;
    }
    .img-color {
        position:absolute;
        top:10px;
        right:10px;
        width:90px !important;
        height:90px !important;
    }
    .options-popup-texture .legend {
        width:100% !important;
        display:block;
        padding-top: 8px;
        margin-top:10px;
    border-top: 1px solid rgba(0,0,0,0.5);
    }
    .option-popup-texture span {
        margin-left:10px;
    }
    .img-option-popup-texture {
        width:20% !important;
    }
    .option-popup-texture {
        width:100% !important;
        display: flex;
    }
    .options-popup-texture {
        flex-direction:column;
    }
    .table-texture 

    {
        font-size:12px !important;
    }
    .title-popup-size,
    .title-texture
    {
        font-size:18px !important;
    }
    
    .title-color {
        font-size:18px !important;
        margin-top:50px !important;
    }
}
.right-glass-option-content
{
    width:70%;
    padding-left:10px;
}
.left-glass-option-content img {
    width:80%;
    display:inline-block;
}
.left-glass-option-content
{
    text-align: center;
    width:30%;
}
.popup-glass-option-content {
    display:flex;
    align-items: center;
}
.glass-type-popup-element {
    font-size:14px;
    padding-top:10px;
    line-height: 1;
    border-bottom:1px solid #ccc;
    padding-bottom:12px;
    width:100%;
}
.title-glass-type-popup {
    font-size:23px;
}
.title-popup-size .arrow-popup-image {
    
    top:0px;
    
}
.arrow-popup-image {
    display:inline-block;
    width:25px;
    margin-right: 10px;
    position: relative;
    top:7px;
    height:25px;
}
.title-popup-size
{
    font-size:23px;
    line-height: 1;
    display: flex;
    text-align: left;
}
.content-size-popup .size-element 
{
    display:table;
    width:100%;
    font-size: 14px;
    line-height: 1;
    border-bottom:1px solid #ccc;
    padding-bottom:12px;
}
.content-size-popup .size-element-right 
{
    display:table-cell;
    text-align: left;
    width:40%;
}
.content-size-popup .size-element-left 
{
    text-align: left;
    display:table-cell;
    width:60%;
}
.content-size-popup .right-size-popup
{
    width:60%;
}
.content-size-popup .left-size-popup
{
    width:40%;
}
.content-size-popup {
    display:flex;
    align-items:center;
    width:100%;
}

.toggle img {
    display:inline-block;
    position:absolute;
    left:8px;
    top:50%;
    transform: translateY(-50%);
}
@media screen and (max-width:760px)
{
    #cart-items .cart-item .remove
    {
        margin-left:4px !important;
    }
    .personnalisation .toggles {
        min-width: 220px !important;
    }
    .like 
    {
        position:absolute !important;
        right: 20px !important;
        left:auto !important;
        top:20px !important;
    }
    .actions-item {
        position: absolute !important;
        float:right;
        margin-top:0 !important;
        top:90px;
        right:20px !important;
        white-space: nowrap;
        left:auto !important;
        width:45% !important;
    }
    body #cart-items .cart-item .name {
        text-align: left !important;
        margin-left:5%;
    }
    body #cart-items .cart-item
    {
        padding:20px 0 !important;
    }
    #cart-items .cart-item .left-cart-item
    {
        width:100% !important;
    }
  .cart-item {
    display:flex;
    flex-direction: column;
  }
}

.question-size {
    display:inline-block;
    position: relative;
    top: 30px;
    margin-left:10px;
    cursor: pointer;
}
.like {
    position: relative;
    top:0;
    min-width: 26px;
    margin-left:30px;
}
.popup-photo-big {
    background:rgba(0,0,0,0.7);
    position:fixed;
    left:0;
    top:0;
    z-index:9999999;
    width:100%;
    height:100%;
}
.popup-photo-big .content .cross {
    position:absolute;
    right:-20px;
    top:-20px;
    cursor:pointer;
    z-index:2;
}

.popup-photo-big .content {
    position:absolute;
    left:50%;
    background:#fff;
    top:50%;
    width:auto;
    
    max-width:80%;
    transform:translate(-50%,-50%);
}
.personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}
.personnalisation .toggles .toggle.active {
    font-weight: 600;
}
.size-description {
    text-align: center !important;
    padding-top:50px;
    padding-bottom:50px;
    font-size: 14px;
}
.personnalisation .toggles .toggle
{
     display:inline-block;
    width:49%;
    font-size:14px;
    padding:10px 20px;
    position:relative;
    z-index:2;
     vertical-align: top;
}
.personnalisation .toggles
{
    margin-top:20px;
    min-width: 240px;
    margin-bottom:20px;
    background:rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    text-align: center;
    position:relative;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display:inline-block;
    width:40%;
    vertical-align: top;
}
.product_detail_content {
    position:relative;
    padding-bottom:5px;
}
.size_choice  .size.active
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.size_choice .size span {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.size_choice .size
{
    margin-bottom:-10px;
  cursor:pointer;
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    display:inline-block;
    border-radius:50%;
    vertical-align: top;
    /* padding-top:10px;
    padding-bottom:10px; */
    padding: 10px 15px;
    width:39px;
    height:39px;
    position: relative;
    margin-left:10px;
    margin-right:10px;
    color:rgba(103, 103, 103, 1);
    white-space: nowrap;
}
.options-scan
{
	box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
	padding-top:10px;
	margin-top:-5px;
	border-bottom-left-radius:30px;
	border-bottom-right-radius:30px;
	padding-bottom:10px;
}

.actions-item {
    display: flex;
    justify-content: center;
    margin-top:-30px;
    position:relative;
    min-width: 144px;
    white-space: nowrap;
    z-index: 2;
}
.options-scan
{
  left:0 !important;
}
.info .link
{
    right:-5px;
    position:relative;
    top:0px !important;
    
}
#popup-cart .cart-content .product-detail label
{
    width:95%;
  display: inline-block;
}
#popup-cart .cart-content .product-detail
{
  font-size:12px;
}
#popup-cart .cart-content .product-detail .subtotal
{
  display: inline-block;
  margin-right: 15px;
      width: auto;
    clear: none;
}
.personnalisation .head
{
    min-width: 280px;
  position: relative;
}
.personnalisation
{
    font-size:12px;

}
#cart-items .cart-item {
    background: #F8F8F8 0% 0% no-repeat padding-box;
border-radius: 13px;
    padding:50px !important;
}
.size_choice
{
  margin-bottom:40px;
  text-align: center;
  display:block;
  border-radius:8px;
  width:290px;
  position:relative;
  padding:20px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

}

.head .info .link
{
    color:rgba(103, 103, 103, 1);
    cursor:pointer;
}
.head .info
{
      position:absolute;
      top:5px;
      right:10px;
        
        text-align: right;
    
}

.title-size
{
    padding-left:10px;
    padding-right:10px;
    margin-top:-15px;
    color:rgba(103, 103, 103, 1);
}

@media screen and (max-width:760px) {
   .title-size {
    margin-top:0 !important;
    margin-bottom:-5px !important;
   } 
}

.personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}
.personnalisation .toggles .toggle
{
     display:inline-block;
    width:49%;
    padding:10px 20px;
    position:relative;
    z-index:2;
     vertical-align: top;
}

.product_detail_content span.detail-custom {
    display:block;
}
.personnalisation .toggles
{
    margin-top:20px;
    min-width: 240px;
    margin-bottom:20px;
    background:rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    text-align: center;
    position:relative;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display:inline-block;
    width:40%;
    vertical-align: top;
}
#popup-cart .cart-content .product-detail.scans label b {
    font-weight: 600; 
}
#popup-cart .cart-content .product-detail.scans
{
  border-bottom:0 !important;
  font-weight: 600;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
border: 1px solid rgba(112, 112, 112, 0);
border-radius: 8px;
    max-width: 280px;
    padding:10px 20px;
}
.options-scan .option:hover
{
  background:rgba(16, 132, 255, 0.1);
}
.options-scan .option
{
  padding:5px;
  cursor: pointer;
}
.scan-choice::before
{
  position:absolute;
  content:' ';
  width:17px;
  height:17px;

  background-size:100% 100%;
  left:10px;
 
  top:10px;
  background-image:url('../../../../public/assets/images/icon_scan.svg');
}
.scan-choice.opened::after {
    transform: rotate(-90deg);
    right:-7px;
}
.scan-choice::after
{
  position:absolute;
  content:' ';
  width:38px;
  height:38px;
  transform: rotate(90deg);
  background-size:100% 100%;
  right:5px;
  color:rgba(16, 132, 255, 1);
  top:-2px;
  background-image:url('../../../../public/assets/images/arrow_scan.svg');
}
.scan-choice
{
    margin-top:5px;
    font-size:10px;
    font-weight: 600;
  left:0;
  cursor: pointer;
  background: transparent linear-gradient(90deg, #5D2BFF 0%, #0000E0 100%) 0% 0% no-repeat padding-box;
border-radius: 8px;
  color:#fff;
  padding-top:10px;
  padding-bottom:10px;
  position:relative;
  padding-left:40px;
  padding-right:0px;
}
.product-detail {
     position:relative;
}
.product-detail:first-child .right-icon{
    top:6px;
}
.product-detail .right-icon {
  float:right;
  position:absolute;
  right:15px;

   top:12px;
}
.product-detail.open .right-icon.minus{
  display:block;
}
.product-detail.open .right-icon.plus{
  display:none;
}
.right-icon.minus {
  display: none;
}


body .popup-size #cross {
    position:absolute !important;
    right:-10px !important;
    top:-10px !important;
    left:auto !important;
 
    z-index: 2;
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16) !important ;
    padding:8px !important;
    background:#fff !important;
    border-radius: 50% !important;
    cursor:pointer;
}
.popup-size .content
{
    z-index: 1;
    background:#fff;
    padding:20px;
    position:absolute;
    top:50%;
    left:50%;
    z-index:8;
    font-size:13px;
    min-width:440px;
    max-width:440px;
    max-height:100vh;
    transform:translate(-50%,-50%);
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16) ;
}
.popup-size {
    position:fixed;
    left:0;
    top:0;
    background:rgba(0, 0, 0, 0.5);
    z-index:9999;
    width:100%;
    height:100%;
}
.cart-items-items {
  display:flex;
  flex-direction: column-reverse;
}

@media screen and (max-width:760px) {
  

   
    #popup-login .content .left {
        width:100% !important;
        z-index:1;
        height:120px !important;
        position:relative !important;

    }
    #popup-login .content .right {
        width:100% !important;
        left:0 !important;
        z-index: 2;
        
    }
}
</style>